import React, { FC } from 'react';
import { graphql, PageProps } from 'gatsby';
import { MainLayout } from '../../templates/MainLayout';
import { SpeechesHeader } from '../../components/speeches/SpeechesHeader';
import { SpeechesList } from '../../components/speeches/SpeechesList';
import { ISpeechesPostsQuery } from '../../queries/posts/types';
import { t } from '../../i18n';

type IProps = PageProps<
  ISpeechesPostsQuery,
  { locale: string; tag: never; page: number }
>;

const Speeches: FC<IProps> = ({
  location,
  data,
  pageContext: { locale = 'ru', tag = '' },
}) => (
  <MainLayout locale={locale} title="speeches.title">
    <SpeechesHeader
      title={t('speeches.title')}
      description={t('speeches.description')}
    />
    <SpeechesList
      from={location.pathname}
      data={data}
      locale={locale}
      tag={tag}
    />
  </MainLayout>
);

export const query = graphql`
  query {
    header: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/speeches/header/header/" } }
    ) {
      ...HeaderFields
    }
    prev: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/speeches/pastSpeeches/" } }
      sort: { fields: fileAbsolutePath, order: DESC }
    ) {
      ...PostsFields
    }
    next: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/speeches/nextSpeeches/" } }
      sort: { fields: fileAbsolutePath, order: DESC }
    ) {
      ...PostsFields
    }
  }
`;

export default Speeches;
